/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import { Helmet } from "react-helmet";
import React from "react";

export type SEOData = {
  description?: string;
  title?: string;
};

export interface SEOProps {
  data?: SEOData;
  language?: string;
  pageTitle?: string;
}

export const SEO: React.FC<SEOProps> = ({
  data,
  language = "de",
  pageTitle,
}) => {
  const metaDescription = data?.description || "ClassPad.academy";
  const defaultTitle = data?.title || "ClassPad.academy";
  const author = "Casio";
  const title = `${pageTitle} | ${defaultTitle}`;

  return (
    <Helmet
      htmlAttributes={{ lang: language }}
      title={title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: pageTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: pageTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]}
    />
  );
};
